
#submission{
	
	float:none;
	margin:0 auto;

	.fieldset{
		display:none;
		min-height: 100px;

	}
	.fieldset.pageOpened{
		display:block;
	}

	.buttonBar .btn{
		margin: 0 2em;
		float:left;
		/*visibility: hidden;*/
	}

	.btn.previousPage{
		margin-left:10%; 
		float:left;
		display: none;
		width:6em;
	}
	.btn.nextPage,.btn.submit{
		margin-right:10%;
		float:right;
		width:6em;

	}

/* 
	{
		width:40%;
		display: inline-block;
	}	 */

	.buttonBar{
		margin:20px 0;
	/*	width: 600px;*/
		float:left;
	}

	[name=fraud_description]{
		width:100%;
		height:100px;
	}

	.explanation{
		display:inline-block;
		width:100%;
		color:#555;
		font-size: 0.9em;
	}

	.submissionExplanation{
		margin-top:30px;
	}

	.submit{
		display:none;
	}

	.uploadArea{
		display: inline-block;
	}

	.field{
		margin-bottom:20px;
		padding:0;
	}

}

#enterInternalSubmission{
	&>div{
		display:inline-block;
	}
	div{
		padding:0;
	}
	label{
		width:initial;
		margin-right: 20px;
	}
}	

#enterConfirmPrivacy{
	label{
		width:100%;
	}
}

#enterCompany{

	[name=company_id], [name=company_name]{
	}
	label{
	    width: 90%;
	}
	.groupName{
	    margin: 0 0 10px 0px; 
	}
	.radioOption, .checkboxes{ 
		padding:0 0 0 15px;
	}
	.checkboxes{
		margin-bottom:8px;
	}

	.companyIcon{
		width: 25px; 
		height:12.5px;
		display: inline-block;
		margin-right: 3px;
		position: relative;
		top: -2px; 

		img{
			width:18px;
			height:12px;
		}
	}
	.other{

		margin-top:2em;
		
		.companyIcon{
			display:none;
		}

	}

}

#enterFraudDescription{
	/*width: 500px;*/
}

#enterLocation{
	.field>div{
		padding:0;
	}
	textarea{
		height: 70px;
		width: 100%;
	}
	label{
		width:100%;
	}
}

#enterDate, #enterPrivyToDate{
	label{
		width: 57%;
	}
	.form-control{
		width:40%;
		display: inline-block;
		margin-left: 2%;
	}		
}

#enterSuspects{

	&>div{
		margin-bottom: 20px;

	}

	.form-control{
		width: 60%;
	    display: inline-block;
	}

	.suspectTitle{
		margin: 0 0 15px 0;
		font-size: 16px;
	}

	label{
		width:30%;
	}

}

#enterFraudType{
	.fraudTypeRadios{
		display: inline-block;
		width:50%;
		@media (max-width: 767px) {
			width:100%;
		}
		label{
			width:90%;
		}
	}
}

#enterAnonymousDecision{
	label{
		width: 62%;
	}
	.wantsToBeAnonymousRadios, .whistleblowerCanBeRevealedRadios{
		width: 30%;
	    display: inline-block;
	    margin-left: 10px;
	}
	.decision{
		margin-top: 10px;
	}
	.questionRow{
		margin-bottom: 10px;
	}
}

#enterOtherPeopleWithKnowledge{

	/*min-width: 700px;*/
	
	label{
		width: 50%;
	    text-align: justify;
	}
	.otherPeopleRadio{
		width: 30%;
		display: inline-block;
		margin-left: 30px;
	}
	#personWithKnowledge{ 
		label{
			width: 100%;
		}
		textarea{
			width: 100%;
			height: 70px;
		}
	}

}

#enterPersonalData{

	label, 
	.leftLabel{
		width:25%; 
		display:inline-block;

	}

	.genderRadios label{
		width:5%;
	}

	.form-control{
		width:55%;
		display: inline-block;
	}

}


.radioOption{
	margin: 0 0 7px 0;
}

.personSuspect{
	display: inline-block;
}




.pageNumber{
	display: inline-block;
	margin-left: 45.5%;
}

.captcha{
	/* display:none; */
	margin:20px 0;
}

#enterLocationDatePrivyToDate{
	.locationPart textarea{
		float: right;
		width:300px;
		height: 50px;
	}

	.datePart input,
	.datePart select,
	.privyToDatePart input,
	.privyToDatePart select
	{
		float: right;
		width:300px;
		display: inline-block;
	}


}