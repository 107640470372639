.userEdit{
	
	&.form-horizontal{
    	width: 350px;
		margin: 100px auto;
	}


	input,
	select{
		width:250px;
	}
	
	.row{
		
		margin-bottom: 1.5em;

		&>div{
			display: inline-block;
		}

		& div:first-child{
			width:120px;
		}
	}
	.submitButtons {

		margin-top: 30px; 
		width:231px;

		button {
			/*width: 8em;*/
		}
		.fa{
			float: right;
			cursor: pointer;
		}

	}
}